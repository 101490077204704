<template>
    <v-container class="d-flex flex-column justify-center">
        <v-sheet
            v-for="item in items"
            :key="item.id"
            outlined
            color="#00000011"
            class="ma-2 pa-4"
        >
            <SpotlightItem
                :item="item"
                :schema="schemas[item.type]"
                @remove="removeItem(item.id)"
                @save="updateItem(item.id, ...arguments)"
            />
        </v-sheet>
        <v-alert
            v-if="items.length == 0"
            type="info"
            colored-border
            color="primary"
            >Click the plus button to add an item to Spotlight.</v-alert
        >
        <v-speed-dial v-model="newButton" fixed bottom right direction="top">
            <template v-slot:activator>
                <v-btn v-model="newButton" color="primary" fab>
                    <v-icon v-if="newButton"> mdi-close</v-icon>
                    <v-icon v-else> mdi-plus</v-icon>
                </v-btn>
            </template>
            <v-btn v-for="schema in schemas" :key="schema.id" @click="newItem">
                New {{ schema.title }}
            </v-btn>
        </v-speed-dial>
    </v-container>
</template>

<script>
export default {
    components: {
        SpotlightItem: () => import('./SpotlightItem'),
    },
    data: () => ({
        newButton: false,
    }),
    computed: {
        schemas() {
            const schemas = this.$store.getters['spotlight/getSchemas']
            return schemas.reduce((result, schema) => {
                result[schema.id] = schema
                return result
            }, {})
        },
        items() {
            return this.$store.getters['spotlight/getItems']
        },
        organisationId() {
            const { organisationId } = this.$store.getters['auth/getUser']
            return organisationId
        },
    },
    methods: {
        newItem() {
            this.$store.dispatch('spotlight/newItem', {
                type: 'message',
                title: '',
                body: '',
                enabled: false,
                organisationId: this.organisationId,
            })
        },
        updateItem(id, data) {
            console.debug(id, data)
            this.$store.dispatch('spotlight/updateItem', { id, data })
        },
        removeItem(id) {
            this.$store.dispatch('spotlight/removeItem', id)
        },
    },
}
</script>

<style scoped>
/deep/ .v-speed-dial__list {
    align-items: flex-end;
}
</style>
